import React from 'react';

import './styles/Header2.css'
class Header2 extends React.Component{



render(){

	const{
     Headerimagen2
	}=this.props

return(

	<div className='hero2'>

   <img className='img-fluid' src={Headerimagen2} alt=""/>

	</div>




	)





}







}


export default Header2;