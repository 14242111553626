import React from 'react';
import  './styles/PageLoading.css'
function PageLoading(){

	return (

<div className="PageLoading">

Loading..

</div>


		)
}


export default PageLoading;