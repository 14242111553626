import React from 'react';

import './styles/Navbar.css';
import Navimagen from '../images/logo.svg';
import {Link} from 'react-router-dom'

class Navbar extends React.Component{

render(){



return(

 
<div  className='Navbar'>
	<div className='container-fluid'>
         <Link className='brand' to="/">
           <img className='logo' src={Navimagen} alt=""/>

           <span className='font-weight-light'>platzi</span>
           <span className='font-wight-bold'>Confg</span>
	     </Link>
    </div>  
	</div>
	)
}

}

export default Navbar;
