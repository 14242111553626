import React from 'react'
import './styles/BadgeList.css';
import imagen  from '../images/pajarot.png';
import {Link} from  'react-router-dom';
class BadgesList extends React.Component {

render(){
if(this.props.badges.length ===0){

return(
 <div>
 	<h3>NO BADGES NOT FOUND</h3>

 	<Link className="btn btn-primary" to="/badges/new">Create new  badge</Link>



 </div>


	)
}
return ( 

	<ul className="list-unstyled"> 
{this.props.badges.map(badge=>{

return(
 <li key={badge.id}>
       
     <div className="listcontainer" >
   <div className="divilist">
   	<img className="listavatar" src={badge.avatarUrl} alt=""/>
   </div>
<div className="listcontenido">
    <div className="lname">
 	<p>{badge.firstName} {badge.lastName}</p>
 	</div>

 	<div className="ltwit">
 	<img className="pajarot" src={imagen} alt=""/>
 		<p>@{badge.twitter}</p>
 	</div>
 	
 	<div className="ljob">
 	<p>{badge.jobTitle}</p>
 	</div>
 </div>	
 </div>

 </li>


	);
})}

</ul>);


}
}


export default BadgesList;