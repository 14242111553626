import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import BadgeNew from '../pages/BadgesNew.js';
import BadgeEdit from '../pages/BadgeEdit.js';
import Badges from '../pages/Badges.js';
import NotFound from '../pages/NotFound.js';
import Layout from '../components/Layout.js';

function App(){

return (

<BrowserRouter>


<Layout>
<Switch>
<Route exact path="/badges" component={Badges} />
<Route exact path="/badges/new" component={BadgeNew} />
<Route exact path="/badges/:badgesId/edit" component={BadgeEdit} />
<Route  component={BadgeNew} />
</Switch>
</Layout>
</BrowserRouter>




	)






}

export default App;