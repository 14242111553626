/*const element = document.createElement('h1');
element.innerText = 'Hello , Platzi Badges';
const container = document.getElementById('app');

container.appendChild(element);

const element = React.createElement('a',{href:'www.redgam.com'},`hola soy ${name} ` )

*/

import React from 'react';
import ReactDom from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css'
import './global.css'
import BadgeNew from './pages/BadgesNew.js';
import Badges from './pages/Badges.js';

import App from './components/App'




const container = document.getElementById('app');

ReactDom.render(<App/> ,container);



