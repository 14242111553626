import React from 'react'
import './styles/BadgeForm.css'
import md5 from 'md5'
class BadgeForm extends React.Component{

constructor() {
    super()
    this.title = React.createRef()
    this.changeColor = this.changeColor.bind( this )
  }

 changeColor() {
   
    console.log(this.title);
    this.title.current.style.display = 'block'
  }

// handleSubmit=e =>{

// e.preventDefault();
// console.log('form was submitted');
// console.log(this.state);
// }

handleClick= e =>{
console.log('se envio');


};


// console.log({



//  //    name: e.target.name,
// 	// value:e.target.value
// 	// 
 


// });

// handleChang= e =>{

// 	  this.setState({

//    	[e.target.name]: e.target.value,
//    });

// };
render(){
const{

  firstName,
  lastName,
  email,
  avatarUrl,
  jobTitle,
  twitter
}=this.props.formValues;
const hash = md5(email);
return(
	<div>
		<h1>Formulario de ingreso</h1>

		<form onSubmit={this.props.onSubmit} action="">

           <div className="form-group">
              <label htmlFor="">Firstname</label>
              <input onChange={this.props.onChange} type="text" value={firstName}  className="form-control" name='firstName'/>
           </div>

           <div className="form-group">
              <label htmlFor="">LastName</label>
              <input onChange={this.props.onChange} type="text" value={lastName} className="form-control" name='lastName'/>
           </div>

           <div className="form-group">
              <label htmlFor="">Email</label>
              <input onKeyUp = { this.changeColor } onChange={this.props.onChange} type="email" value={email} className="form-control" name='email'/>
           </div>


           <div className="form-group">
              <label htmlFor="">JobTitle</label>
              <input onChange={this.props.onChange} type="text" value={jobTitle} className="form-control" name='jobTitle'/>
           </div>


           <div className="form-group">
              <label htmlFor="">Twitter</label>
              <input onChange={this.props.onChange} type="text" value={twitter} className="form-control" name='twitter'/>
           </div>
          

           <div  ref = {this.title} className="form-grouph">
              <label  htmlFor="">Desea Agregar la imagen del  avatar </label>
              <input  onChange={this.props.onChange} type="checkbox" value={`https://s.gravatar.com/avatar/${hash}?s=80`}  className="form-control" name='avatarUrl'/>
           </div>
{this.props.error && <p className="text-danger">{this.props.error.message}</p>}

<button  onClick={this.handleClick} className="btn btn-primary">Save</button>


		</form>



	</div>
	)


}


}


export default BadgeForm;