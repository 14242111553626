import React from 'react'

import Badge from '../components/Badge.js'
import Navbar from  '../components/Navbar'
import Header2 from  '../components/Header2'
import BadgeForm from  '../components/BadgeForm'
import PageLoading from '../components/PageLoading';

import Headerimagen2 from  '../images/logo conf.svg'
import api from '../api.js'
class BadgeNew extends React.Component{

	state ={

loading: false,
error: null,
    form:{
     
       firstName:'',
  lastName:'',
  email:'',
  avatarUrl:'',
  jobTitle:'',
  twitter:''
	


  }};


  handleChange = e => {

     this.setState({
     form:{
...this.state.form,
[e.target.name]:e.target.value,

     },


     });


  };

  handleSubmit = async e =>{

    e.preventDefault()


    try{

      await api.badges.create(this.state.form);
      this.setState({loading:false})
      this.props.history.push('/badges');
    }catch(error){
      this.setState({loading:false,error:error})
    }
  }

render(){

if (this.state.loading) {

  return <PageLoading />
}

const{

  firstName,
  lastName,
  email,
  avatarUrl,
  jobTitle,
  twitter
}=this.state.form
return(


<div>

        <div >
  
            <Header2 
     
                    Headerimagen2={Headerimagen2}
              />
        </div>

        <div className='container'>

            <div className="row">

               <div className="col-6">
	
				<Badge firstName={firstName||'Nombre'}
					   lastName={lastName || 'Apellido'}
					   jobTitle={jobTitle || 'Ocupación'}
					   twitter={twitter ||'@tatatata'}
             email={email || 'fsfs@suemail.com'}
					    avatarUrl={avatarUrl||'hhuihui'} />
              </div>

              <div className='col-6'>
              	
               <BadgeForm onChange={this.handleChange}
                          onSubmit={this.handleSubmit}
                          formValues={this.state.form}
                          error={this.state.error}


                           />

              </div>
            </div>
        </div>
</div>
	

)
}
}

export default BadgeNew;