import React from 'react';



import imagen  from '../images/badge-header.svg';

import './styles/Badge.css';
import Gravatar from './Gravatar.js';

class Badge extends React.Component{

render(){

const {

	firstName,
	lastName,
	avatar,
	jobTitle,
	email,
	twitter
}=this.props;

return(


	/*esto es un comentario*/
<div className="badge">
	

    {/*este es el  header del badge*/}
	<div className="header">

		<img src={imagen} alt=""/>
	</div>

	<div className='section-name'>
		<Gravatar email={email} alt=""/>
		<h1>{firstName}<br/> {lastName}</h1>
    </div>
		<div className='section-info'>
			<h3>{jobTitle}</h3>
			<div>@{twitter}</div>
		</div>

		<footer className='footer'>
			#platzi

		</footer>
	</div>
	)


}


}

export default Badge;